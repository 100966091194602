export const sodaData = [
  {
    name: "FUZETEA",
    price: 9,
  },
  {
    name: "CAPPY",
    price: 7,
  },
  {
    name: "COCA-COLA 0,5L",
    price: 9,
  },
  {
    name: "SPRITE 0,5L",
    price: 9,
  },
  {
    name: "FANTA 0,5L",
    price: 9,
  },
  {
    name: "MONSTER",
    price: 12,
  },
  {
    name: "WODA 0,5L",
    price: 6,
  },
  {
    name: "LEMONIADA",
    price: 12,
  },
  {
    name: "PIWO",
    price: 9,
  },
];
