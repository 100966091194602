export const saladData = [
  {
    name: "GRECKA",
    polishIngredients:
      "sałata, pomidor, ogórek, cebula, ser feta, oliwki, sos grecki",
    englishIngredients:
      "Salad, tomato, cucumber, onion, feta cheese, olives, greek sauce",
    price: 28,
  },
  {
    name: "CEZAR",
    polishIngredients:
      "sałata, rukola, kurczak, ogórek, pomidor, papryka, kukurydza, majonez, jajko",
    englishIngredients:
      "Salad, rucola, chicken, cucumber, tomato, pepper, corn, mayo, egg",
    price: 29,
  },
  {
    name: "ŁOSOSIOWA",
    polishIngredients:
      "sałata, łosoś wędzony, ogórek, pomidor, cebula czerwona, kiełki",
    englishIngredients:
      "Salad, smoked salmon, cucumber, tomato, red onion, sprouts",
    price: 30,
  },
];
