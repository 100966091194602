export const mainDishData = [
  {
    name: "SKRZYDEŁKA",
    polishIngredients: "Skrzydełka (6 szt.), frytki",
    englishIngredients: "Chicken wings (6 pcs.), fries",
    price: 29,
  },
  {
    name: "NUGGETSY",
    polishIngredients: "Nuggetsy (8 szt.), frytki, ketchup",
    englishIngredients: "Nuggets (8 pcs.), fries, ketchup",
    price: 28,
  },
  // {
  //   name: "MIRUNA W CIEŚCIE",
  //   polishIngredients: "Miruna w cieście piwnym (4 szt.), frytki, sałatka",
  //   englishIngredients: "4 pieces fish, french fries, salad",
  //   price: 28,
  // },
];
