export const pizzaData = [
  {
    name: "MARGHERITA",
    polishIngredients:
      "Pomidory san marzano, parmigiano regiano, mozarella fior di latte, bazylia",
    englishIngredients:
      "San marzano tomatoes, parmigiano regiano, fior di latte mozarella, basil",
    smallPrice: 28,
  },
  {
    name: "PROSCIUTTO CRUDO",
    polishIngredients:
      "Pomidory san marzano, parmigiano regiano, mozarella fior di latte, prosciutto crudo, pomidorki koktajlowe, rukola",
    englishIngredients:
      "San marzano tomatoes, parmigiano regiano, fior di latte mozarella, prosciutto crudo, cherry tomatoes, arugula",
    smallPrice: 33,
  },
  {
    name: "PROSCIUTTO E FUNGHI",
    polishIngredients:
      "Pomidory san marzano, parmigiano regiano, mozarella fior di latte, prosciutto cotto, pieczarki, bazylia",
    englishIngredients:
      "San marzano tomatoes, parmigiano regiano, fior di latte mozarella, prosciutto crudo, mushrooms, basil",
    smallPrice: 32,
  },
  {
    name: "DIAVOLA",
    polishIngredients:
      "Pomidory san marzano, parmigiano regiano, mozarella fior di latte, salami spianata piccante, burrata, bazylia",
    englishIngredients:
      "San marzano tomatoes, parmigiano regiano, fior di latte mozarella, salami spianata piccante, burrata, basil",
    smallPrice: 38,
  },
  {
    name: "CONTRASTO",
    polishIngredients:
      "Pomidory san marzano, mozarella fior di latte, salami spianata piccante, pomidorki koktajlowe, krem balsamiczny, rukola",
    englishIngredients:
      "San marzano tomatoes, fior di latte mozarella, salami spianata piccante, cherry tomatoes, balsamic cream, arugula",
    smallPrice: 34,
  },
  {
    name: "PANCETTA ARROTOLATA",
    polishIngredients:
      "Śmietanka, parmigiano reggiano, mozarella fior di latte, pesto bazyliowe, pancetta arratolata, pieczarki",
    englishIngredients:
      "Cream, parmigiano reggiano, fior di latte mozarella, basil pesto, pancetta arratolata, mushrooms",
    smallPrice: 34,
  },
  {
    name: "CHORIZO & GRUSZKA",
    polishIngredients:
      "Śmietanka, mozarella fior di latte, gruszka, ser kozi, szarpane chorizo, balsam gruszkowy",
    englishIngredients:
      "Cream, fior di latte mozarella, pear, goat cheese, pulled chorizo, pear balsam",
    smallPrice: 35,
  },
  {
    name: "SALAMI",
    polishIngredients:
      "Pomidory san marzano, parmigiano reggiano, mozarella fior di latte, salami milano, bazylia",
    englishIngredients:
      "San marzano tomatoes, parmigiano reggiano, fior di latte mozarella, salami milano, basil",
    smallPrice: 33,
  },
  {
    name: "VEGE",
    polishIngredients:
      "Pomidory san marzano, parmigiano reggiano, mozarella fior di latte, bakłażan, cukinia, cebula, papryka czerwona, rukola, balsam malinowy",
    englishIngredients:
      "San marzano tomatoes, parmigiano reggiano, fior di latte mozarella, eggplant, zucchini, onion, red pepper, arugula, raspberry balsam",
    smallPrice: 34,
  },
  {
    name: "PROSCIUTTO COTTO",
    polishIngredients:
      "Pomidory san marzano, parmigiano reggiano, mozarella fior di latte, prosciutto cotto, bazylia",
    englishIngredients:
      "San marzano tomatoes, parmigiano reggiano, fior di latte mozarella, prosciutto cotto, basil",
    smallPrice: 31,
  },
  {
    name: "HUCI",
    polishIngredients:
      "Pomidory san marzano, mozarella fior di latte, pancetta arrotolata, salami milano, cebula, jallapeno",
    englishIngredients:
      "San marzano tomatoes, fior di latte mozarella, pancetta arrotolata, salami milano, onion, jallapeno",
    smallPrice: 35,
  },
  {
    name: "4 SERY",
    polishIngredients:
      "Pomidory san marzano, parmigiano reggiano, mozarella fior di latte, feta, gorgonzola, bazylia",
    englishIngredients:
      "San marzano tomatoes, parmigiano reggiano, fior di latte mozarella, feta cheese, gorgonzola, basil",
    smallPrice: 35,
  },
  {
    name: "BUFALINO",
    polishIngredients:
      "Śmietanka, mozarella di bufala, pesto bazyliowe, anchois, cebula, pomidorki koktajlowe, jallapeno",
    englishIngredients:
      "Cream, mozarella di bufala, basil pseto, anchois, onion, cherry tomatoes, jallapeno",
    smallPrice: 36,
  },
];
