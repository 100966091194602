export const burgerData = [
  {
    name: "BASIC BURGER",
    polishIngredients:
      "Mięso wołowe 200g, sałata, ogórek konserwowy, pomidor, cebula, majonez, ketchup",
    englishIngredients:
      "Beef 200g, salad, picked cucumber, tomato, onion, mayo, ketchup",
    price: 29,
  },
  {
    name: "BEKON BURGER",
    polishIngredients:
      "Mięso wołowe 200g, bekon, sałata, ogórek konserwowy, pomidor, cebula, majonez, ketchup",
    englishIngredients:
      "Beef 200g, bacon, salad, picked cucumber, tomato, onion, mayo, ketchup",
    price: 31,
  },
  {
    name: "CHEESE BURGER",
    polishIngredients:
      "Mięso wołowe 200g, ser cheddar, sałata, ogórek konserwowy, pomidor, majonez, ketchup",
    englishIngredients:
      "Beef 200g, cheddar, salad, picked cucumber, tomato, mayo, ketchup",
    price: 30,
  },
  {
    name: "DOUBLE BURGER",
    polishIngredients:
      "Mięso wołowe 400g, ser cheddar x2, sałata, ogórek konserwowy, pomidor, cebula, majonez, ketchup",
    englishIngredients:
      "Beef 400g, double cheddar, salad, picked cucumber, tomato, onion, mayo, ketchup",
    price: 37,
  },
  {
    name: "DALLAS BURGER",
    polishIngredients:
      "Mięso wołowe 200g, bekon, krążki cebulowe, sałata, ogórek konserwowy, cebula, majonez, BBQ",
    englishIngredients:
      "Beef 200g, bacon, onion rings, salad, onion, picked cucumber, mayo, BBQ sauce",
    price: 33,
  },
  {
    name: "SPICY BURGER",
    polishIngredients:
      "Mięso wołowe 200g, jalapeno, ser cheddar, sałata, ogórek konserwowy, pomidor, cebula, ostry sos, ketchup",
    englishIngredients:
      "Beef 200g, jalapeno, cheddar, salad, picked cucumber, tomato, onion, spicy sauce, ketchup",
    price: 31,
  },
  {
    name: "K-2 BURGER",
    polishIngredients:
      "Mięso wołowe 150g, grilowany ser, sałata, rukola, kiełki, pomidor, cebula, majonez, ketchup",
    englishIngredients:
      "Beef 150g, breaded cheese, salad, rucola, sprouts, tomato, onion, mayo, ketchup",
    price: 33,
  },
  {
    name: "MUSH BURGER",
    polishIngredients:
      "Mięso wołowe 200g, pieczarki, bekon, sałata, pomidor, rukola, cebula, majonez, BBQ",
    englishIngredients:
      "Beef 200g, mushrooms, becon, salad, tomato, onion, rucola, mayo, BBQ sauce",
    price: 32,
  },
  {
    name: "EGG BURGER",
    polishIngredients:
      "Mięso wołowe 200g, jajko sadzone, bekon, sałata, ogórek konserwowy, pomidor, cebula, majonez, BBQ",
    englishIngredients:
      "Beef 200g, egg, bacon, salad, picked cucumber, tomato, onion, mayo, BBQ sauce",
    price: 32,
  },
  {
    name: "WEGE BURGER",
    polishIngredients:
      "Serek panierowany/krążki cebulowe, rukola, sałata, ogórek, pomidor, cebula, kiełki, majonez, ketchup",
    englishIngredients:
      "Breaded cheese/onion rings, rucola, salad, tomato, cucumber, onion, sprouts, mayo, ketchup",
    price: 27,
  },
  {
    name: "NACHOS BURGER",
    polishIngredients:
      "Mięso wołowe 200g, nachosy, ser cheddar, sałata, cebula, sos serowy, BBQ",
    englishIngredients:
      "Beef 200g, nachos, cheddar, salad, onion, cheese sauce, BBQ",
    price: 31,
  },
  {
    name: "CHICKEN BURGER",
    polishIngredients:
      "Kurczak 200g, ser feta, sałata, pomidor, cebula, kukurydza, ketchup, sos majonezowy",
    englishIngredients:
      "Chicken 200g, feta cheese, salad, tomato, onion, corn, ketchup, mayo sauce",
    price: 29,
  },
  {
    name: "JALLAPENO BURGER",
    polishIngredients:
      "Mięso wołowe 200g, ser cheddar x2, bekon x2, rukola, jalapeno, cebula, ketchup, sos majonezowy",
    englishIngredients:
      "Beef 200g, cheddar x2, bacon x2, rucola, jalapeno, onion, ketchup, mayo sauce",
    price: 33,
  },
  {
    name: "GREEK BURGER",
    polishIngredients:
      "Mięso wołowe 200g, sałata, ser feta, oliwki, pomidor, cebula, ketchup, sos majonezowy",
    englishIngredients:
      "Beef 200g, salad, feta cheese, olives, tomato, onion, ketchup, mayo sauce",
    price: 32,
  },
];
