export const sauceData = [
  { name: "KETCHUP", price: 3 },
  { name: "BBQ", price: 3 },
  { name: "MAJONEZ", price: 3 },
  { name: "VINEGRET", price: 4 },
  { name: "CZOSNKOWY", price: 4 },
  { name: "POMIDOROWY", price: 4 },
  { name: "HABANERO", price: 4 },
  { name: "SEROWY", price: 4 },
  { name: "SWEET CHILLI", price: 4 },
];
